import { ContactSectionDTO } from '~/pages/careers/types';

import photo from './assets/photo.png';
import drawing from './assets/drawing.svg';

const contactSection: ContactSectionDTO = {
  title: 'Still not sure? Chat with us!',
  buttonUrl: 'https://m.me/swingdev/',
  buttonLabel: 'Chat with us',
  photo: {
    src: photo,
    alt: 'Building',
  },
  drawing: {
    src: drawing,
    alt: 'Shapes',
  },
};

export default contactSection;
