import { CultureSectionDTO } from '~/pages/careers/types';

const cultureSection: CultureSectionDTO = {
  title: 'About Our Culture',
  subtitle:
    'Once you get used to the whole Swing idea, there is no workplace where your skills and personality will be more valued.',
  quotes: [
    'Be curious. 🧐',
    'Don’t be afraid to experiment… 👾 ❓ 🤔',
    '💡 …and to use your own solutions',
    'Use positive examples 🤠, not criticism. 👺',
    'Treat everybody with respect. 🤜🤛',
    'Enjoy the journey 🎢, not only the end goal. 🏆',
    'Value quality 💪 over quantity. 🤯🤯🤯',
    'Remember, you win and lose as a team, not as team members. 👨‍💻👩‍💻✌️',
  ],
};

export default cultureSection;
