import { JobOfferDTO } from '../../types';
import AndroidLogo from './assets/android-icon.svg';
import AppleLogo from './assets/apple-icon.svg';
import NodeLogo from './assets/js-icon.svg';
import PmLogo from './assets/pm-icon.svg';
import ReactLogo from './assets/react-icon.svg';
import SdLogo from './assets/swingdev-icon.svg';

const icons = {
  'front-end': ReactLogo,
  react: ReactLogo,
  android: AndroidLogo,
  node: NodeLogo,
  ios: AppleLogo,
  qa: PmLogo,
};

export const getIconUrl = (name: string = '') => {
  const iconName = Object.keys(icons).find((key: string) =>
    new RegExp(key, 'ig').test(name),
  );

  if (iconName) {
    return icons[iconName];
  }

  return SdLogo;
};

export const moveGenericOfferToEnd = (value: JobOfferDTO[]) => {
  const genericOfferId = '50960d34-3c5f-56dd-982c-e045896a60b7';
  const genericOfferIndex = value.findIndex(
    (item) => item.id === genericOfferId,
  );
  if (genericOfferIndex !== -1) {
    const genericOfferArray = value.splice(genericOfferIndex, 1);
    value.push(...genericOfferArray);
  }
};
