import React, { useState, useEffect, useCallback } from 'react';
import chunk from 'lodash/chunk';
import debounce from 'lodash/debounce';

import { Breakpoint } from '~/constants';
import { BrowserService } from '~/services/Browser';
import Carousel, { CarouselSettings } from '~/components/Carousel';

import { BenefitDTO, BenefitsSectionDTO } from '../../types';

import styles from './Benefits.module.scss';

type Props = {
  data: BenefitsSectionDTO;
};

const carouselSettings: CarouselSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: styles.dots,
};

const renderBenefit = (benefit: BenefitDTO, index: number) => (
  <div className={styles.item} key={index}>
    <article className={styles.benefit}>
      <figure className={styles.figure}>
        <img
          className={styles.icon}
          src={benefit.image.src}
          alt={benefit.image.alt}
        />
      </figure>

      <h3 className={styles.title}>{benefit.title}</h3>
    </article>
  </div>
);

const renderThreeAtOnce = (benefits: BenefitDTO[]) => {
  const chunkedBenefits = chunk(benefits, 3);

  return chunkedBenefits.map((chunked, index) => (
    <div key={index}>{chunked.map(renderBenefit)}</div>
  ));
};

const shouldShowSlider = () => {
  return BrowserService.getWindowSize() < Breakpoint.LARGE;
};

const Benefits: React.FC<Props> = ({ data }) => {
  const [showMobileSlider, setShowMobileSlider] = useState<boolean | undefined>(
    undefined,
  );

  const debouncedHandleResize = debounce(() => {
    setShowMobileSlider(shouldShowSlider());
  }, 100);

  useEffect(() => {
    BrowserService.resize.addResizeListener(debouncedHandleResize);

    return () => {
      BrowserService.resize.removeResizeListener(debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  useEffect(() => {
    setShowMobileSlider(shouldShowSlider());
  }, []);

  const renderSlider = useCallback(() => {
    return (
      <Carousel
        key='benefits-slider'
        className={styles.slider}
        {...carouselSettings}
      >
        {renderThreeAtOnce(data.items)}
      </Carousel>
    );
  }, [data.items]);

  const renderBenefits = useCallback(() => {
    return (
      <div key='benefits-list' className={styles.container}>
        {data.items.map(renderBenefit)}
      </div>
    );
  }, [data.items]);

  return (
    <section className={styles.root}>
      <h2 className={styles.headline}>{data.title}</h2>
      {showMobileSlider ? renderSlider() : renderBenefits()}
    </section>
  );
};

export default Benefits;
