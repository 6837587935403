import React from 'react';

import { InterviewSlideDTO } from '../../../types';

import styles from './InterviewElement.module.scss';

interface InterviewElementProps extends InterviewSlideDTO {
  step: number;
}

const InterviewElement: React.FC<InterviewElementProps> = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.photoContainer}>
        <video className={styles.video} loop autoPlay muted playsInline>
          <source src={props.video} type='video/mp4' />
        </video>
        <img
          className={styles.cover}
          src={props.videoPreview}
          alt={props.name}
          role='presentation'
        />
      </div>
      <article className={styles.contentContainer}>
        <small className={styles.step}>Step {props.step}</small>
        <h3 className={styles.title}>{props.name}</h3>
        <p className={styles.content}>{props.description}</p>
      </article>
    </div>
  );
};

export default InterviewElement;
