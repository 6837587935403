import { RecommendationsSectionDTO } from '~/pages/careers/types';

import clutchBackground from './assets/clutch-background.jpg';
import clutchLogo from './assets/clutch-logo.svg';
import glassdoorLogo from './assets/glassdoor-logo.svg';

const recommendationsSection: RecommendationsSectionDTO = {
  items: [
    {
      logo: {
        src: clutchLogo,
        alt: 'Clutch',
      },
      title: '“Top Web Design Agencies in San Francisco“',
      buttonUrl: 'https://clutch.co/profile/swingdev',
      buttonLabel: 'Clutch profile',
      backgroundImage: {
        src: clutchBackground,
        alt: '',
      },
    },
    {
      logo: {
        src: glassdoorLogo,
        alt: 'Glassdoor',
      },
      title: "“The best company I've worked for”",
      buttonUrl:
        'https://www.glassdoor.com/Overview/Working-at-Swing-Development-EI_IE1690357.11,28.htm',
      buttonLabel: 'Glassdoor profile',
    },
  ],
};

export default recommendationsSection;
