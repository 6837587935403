import React from 'react';

import { AnalyticsService } from '~/services/Analytics';

import { Card } from './Card';
import { JobOfferDTO, JobsSectionDTO } from '../../types';

import styles from './Jobs.module.scss';

type Props = {
  data: JobsSectionDTO;
  innerRef: React.RefObject<HTMLDivElement>;
};

const renderCard = ({
  id,
  title,
  url,
  location,
  soon,
}: JobOfferDTO & { soon?: boolean }) => {
  const handleOnClick = () => {
    AnalyticsService.track({
      category: 'Careers',
      action: 'Job Offer Click',
      label: `${title} | ${url}`,
    });
  };

  return (
    <div className={styles.column} key={id}>
      <Card
        title={title}
        description={location}
        url={url}
        buttonLabel='More info'
        onClick={handleOnClick}
        soon={soon}
        showIcon
        externalLink
      />
    </div>
  );
};

const Jobs: React.FC<Props> = ({ data, innerRef }) => {
  return (
    <div className={styles.root} ref={innerRef}>
      <div className={styles.container}>
        <h1 className={styles.sectionTitle}>Our open positions</h1>
        <div className={styles.row}>{data.items.map(renderCard)}</div>
      </div>
    </div>
  );
};

export default Jobs;
