import classNames from 'classnames';
import React, { useCallback } from 'react';

import ArrowDown from '~/assets/icons/arrow-down.svg';

import { NAVIGATION_HEIGHT } from '~/constants';
import { BrowserService } from '~/services/Browser';
import Image from '~/components/Image';
import { ImageDTO } from '~/types';

import styles from './HeroImage.module.scss';

type Props = {
  data: {
    title: string;
    subtitle: string;
    image: ImageDTO;
    buttonLabel: string;
  };
  scrollToRef: React.RefObject<HTMLDivElement>;
};

const HeroImage: React.FC<Props> = ({ data, scrollToRef }) => {
  const { title, subtitle, image, buttonLabel } = data;

  const handleScrollToClick = useCallback(() => {
    if (scrollToRef && scrollToRef.current) {
      BrowserService.scroll.scrollTo(scrollToRef.current, {
        offset: NAVIGATION_HEIGHT,
      });
    }
  }, [scrollToRef]);

  const classes = classNames(styles.root);

  return (
    <section className={classes}>
      {image.src && (
        <picture className={styles.photo}>
          <Image img={image.src} alt={image.alt} className={styles.image} />
        </picture>
      )}

      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />

        {buttonLabel && scrollToRef ? (
          <button
            className={styles.button}
            type='button'
            onClick={handleScrollToClick}
          >
            <div className={styles.text}>{buttonLabel}</div>

            <img
              className={styles.icon}
              src={ArrowDown}
              alt=''
              role='presentation'
            />
          </button>
        ) : null}
      </div>
    </section>
  );
};

export default HeroImage;
