import { CareersDTO } from '../../pages/careers/types';

import metadata from './metadata';
import entrySection from './entrySection';
import jobsSection from './jobsSection';
import skillsSection from './skillsSection';
import interviewSection from './interviewSection';
import knowledgeSection from './knowledgeSection';
import cultureSection from './cultureSection';
import recommendationsSection from './recommendationsSection';
import benefitsSection from './benefitsSection';
import contactSection from './contactSection';

const careers: CareersDTO = {
  metadata,
  entrySection,
  jobsSection,
  skillsSection,
  interviewSection,
  knowledgeSection,
  cultureSection,
  recommendationsSection,
  benefitsSection,
  contactSection,
};

export default careers;
