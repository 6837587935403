import { InterviewSectionDTO } from '~/pages/careers/types';

import apply from './assets/01-apply.mp4';
import applyPreview from './assets/01-apply-preview.jpg';

import recruiter from './assets/05-recruiter.mp4';
import recruiterPreview from './assets/05-recruiter-preview.jpg';

import technicalInterview from './assets/02-technical-interview.mp4';
import technicalInterviewPreview from './assets/02-technical-interview-preview.jpg';

import interview from './assets/03-interview.mp4';
import interviewPreview from './assets/03-interview-preview.jpg';

import offer from './assets/04-offer.mp4';
import offerPreview from './assets/04-offer-preview.jpg';

const entrySection: InterviewSectionDTO = {
  items: [
    {
      name: 'Apply with your resume',
      description:
        'Send us your CV or a showcase of the work you’ve done. If your experience fits our requirements, our Recruitment Specialist will contact you to present the recruitment process and to arrange the next steps.',
      video: apply,
      videoPreview: applyPreview,
    },
    {
      name: 'Meet our recruiter',
      description:
        'Let’s break the ice! During our Zoom meeting we’ll chat about your goals and learn more about you. We’ll also tell you about us and our projects, so feel free to ask questions and see if we’re a fit for what you’re looking for.',
      video: recruiter,
      videoPreview: recruiterPreview,
    },
    {
      name: 'Tech skills check',
      description:
        'Your skills are a crucial factor that helps us choose the best talents. Depending on the position you applied for, we usually have a maximum of two meetings to review your tech experience. For engineering roles, our developers will conduct two stages: a 30-minute tech interview and a live coding session.',
      video: technicalInterview,
      videoPreview: technicalInterviewPreview,
    },
    {
      name: 'Short bigwigs’ catch up',
      description:
        'To get a better view of who we are we’d like you to meet the people you’ll work with. So, at this step you’ll talk with people who run the company and your future manager. Remember, if you want to ask about something - do it!',
      video: interview,
      videoPreview: interviewPreview,
    },
    {
      name: 'Time for the result',
      description:
        'Within a few days, we’ll give you feedback about our recruitment process (via call/email). If everything went well, you’ll receive an offer, and then, it’s us waiting for your decision :D And don’t hesitate to reach out if you need to clarify any matter or feedback ASAP - we’re here for you.',
      video: offer,
      videoPreview: offerPreview,
    },
  ],
};

export default entrySection;
