import React from 'react';

import careers from '~/data/careers';

import Layout from '~/components/Layout';

import Jobs from './components/Jobs';
import Skills from './components/Skills';
import Interview from './components/Interview';
import Benefits from './components/Benefits';

import { LeverJobOfferDTO, JobOfferDTO } from './types';
import { useStaticQuery, graphql } from 'gatsby';
import HeroImage from '~/components/HeroImage';
import KnowledgeSharing from './components/KnowledgeSharing';
import { moveGenericOfferToEnd } from './components/Jobs/utils';

type JobsFeedQueryResult = {
  allLever: {
    nodes: LeverJobOfferDTO[];
  };
};

const Careers = () => {
  const {
    metadata,
    entrySection,
    jobsSection,
    skillsSection,
    interviewSection,
    benefitsSection,
    knowledgeSection,
  } = careers;

  const queryResult: JobsFeedQueryResult = useStaticQuery(graphql`
    query {
      allLever(sort: { order: DESC, fields: createdAt }) {
        nodes {
          text
          categories {
            location
            commitment
          }
          id
          hostedUrl
        }
      }
    }
  `);

  const jobOffersFromLever: JobOfferDTO[] = queryResult.allLever.nodes.map(
    (leverOffer) => ({
      id: leverOffer.id,
      title: leverOffer.text,
      url: leverOffer.hostedUrl,
      location: leverOffer.categories.location,
      commitment: leverOffer.categories.commitment,
    }),
  );

  moveGenericOfferToEnd(jobOffersFromLever);

  const allJobOffers = [...jobOffersFromLever, ...careers.jobsSection.items];

  const jobsRef: React.RefObject<HTMLDivElement> = React.createRef();

  return (
    <Layout
      pageTitle={metadata.pageTitle}
      metaDescription={metadata.metaDescription}
    >
      <HeroImage scrollToRef={jobsRef} data={entrySection} />
      <Jobs data={{ ...jobsSection, items: allJobOffers }} innerRef={jobsRef} />
      <Skills data={skillsSection} />
      <Interview data={interviewSection} />
      <KnowledgeSharing data={knowledgeSection} />
      <Benefits data={benefitsSection} />
    </Layout>
  );
};

export default Careers;
