import React from 'react';

import { SkillsSectionDTO, SkillDTO } from '../../types';

import styles from './Skills.module.scss';

type Props = {
  data: SkillsSectionDTO;
};

const renderSkillEntry = (skill: SkillDTO, index: number) => (
  <article key={index} className={styles.entry}>
    <div className={styles.contentContainer}>
      <h3 className={styles.title}>{skill.name}</h3>
      <p
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: skill.description }}
      />
    </div>
    <div className={styles.pictureContainer}>
      <img
        className={styles.picture}
        src={skill.image.src}
        alt={skill.image.alt}
        role='presentation'
        placeholder='color'
      />
    </div>
  </article>
);

const Skills: React.FC<Props> = ({ data }) => {
  return (
    <section className={styles.root}>
      {data.items.map(renderSkillEntry)}
      <h2 className={styles.sectionTitle}>{data.title}</h2>
    </section>
  );
};

export default Skills;
