import { SkillsSectionDTO } from '~/pages/careers/types';

import whoWeAre from './assets/who_are_we.jpg';
import enjoyTheRide from './assets/enjoy_the_ride.jpg';
import newOpportunities from './assets/new_opportunities.jpg';
import ourMission from './assets/our-mission.jpg';

const skillsSection: SkillsSectionDTO = {
  title: 'We need your skills and dedication to make it happen.',
  items: [
    {
      name: 'Who we are',
      description:
        'SwingDev is part of a fast-growing InsurTech company from Silicon Valley – Hippo Insurance. <br>Our technology-first approach is modernizing the otherwise stale home insurance industry, from IOT monitoring devices to our industry-leading software.',
      image: {
        src: whoWeAre,
        alt: 'swingdev employees',
      },
    },
    {
      name: 'Enjoy the ride',
      description:
        'We strive for a human approach in everything we do. <br>At SwingDev, you can be 100% yourself, with a team working as one, sharing, having fun, and making friendships that last.',
      image: {
        src: enjoyTheRide,
        alt: 'swingdev trip to Cyprus',
      },
    },
    {
      name: 'New opportunities',
      description:
        'Partnering closely with the US and Israel based teams keeps us open to different approaches and mindsets. We strive to use “and” rather than “but” when it comes to new ideas.',
      image: {
        src: newOpportunities,
        alt: 'swingdev employees working remotely from italy',
      },
    },
    {
      name: 'Our Mission',
      description:
        'We are on a mission to translate innovation into life by modernizing the home insurance industry.',
      image: {
        src: ourMission,
        alt: 'software developer working on notebook',
      },
    },
  ],
};

export default skillsSection;
