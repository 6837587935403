import React, {
  RefObject,
  TouchEvent,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '~/assets/icons/slider-arrow.svg';
import { AnalyticsService } from '~/services/Analytics';
import { BrowserService } from '~/services/Browser';

import { InterviewSectionDTO, InterviewSlideDTO } from '../../types';

import InterviewElement from './InterviewElement';
import styles from './Interview.module.scss';

type Props = {
  data: InterviewSectionDTO;
};

const SWIPE_THRESHOLD = 1 / 16;
const slider: RefObject<Slider> = React.createRef();
const container: RefObject<HTMLElement> = React.createRef();

const renderInterviewElement = (item: InterviewSlideDTO, index: number) => (
  <InterviewElement key={index} {...item} step={index + 1} />
);

const Interview: React.FC<Props> = ({ data }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [touch, setTouch] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  // Let's send event every time `currentSlideIndex` is changed by a user action.
  // We need to exclude first content update to avoid sending change event when /careers page does the initial load.
  const isFirstUpdate = useRef<boolean>(true);
  useLayoutEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    } else {
      AnalyticsService.track({
        category: 'Careers',
        action: 'Change Interview Slide',
        label: `To "${data.items[currentSlideIndex].name}"`,
      });
    }
  }, [data.items, currentSlideIndex]);

  const afterChange = () => {
    if (container.current) {
      const videos = container.current.querySelectorAll('video');

      Array.from(videos)
        .filter((video) => {
          if (video.parentElement) {
            return video.parentElement.getBoundingClientRect().left === 0;
          } else {
            return false;
          }
        })
        .forEach((video) => {
          const playPromise = video.play();

          if (playPromise) {
            playPromise.catch((error) => console.error(error));
          }
        });
    }
  };

  const beforeChange = (oldIndex: number, newIndex: number) => {
    setCurrentSlideIndex(newIndex);

    if (container.current) {
      const videos = container.current.querySelectorAll('video');

      // React-slick creates numberOfSlides * 2 + 1 video instances
      Array.from(videos).forEach((video) => {
        video.pause();
        video.currentTime = 0;
      });
    }
  };

  const handleDotClick = (index: number) => {
    if (slider.current) {
      slider.current.slickGoTo(index);
    }
  };

  const handlePrev = () => {
    if (slider.current) {
      slider.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (slider.current) {
      slider.current.slickNext();
    }
  };

  const initSectionTouch = (event: TouchEvent<HTMLElement>) => {
    const touch = event.touches[0];

    setTouch({
      x: touch.clientX,
      y: touch.clientY,
    });
  };

  const handleSectionTouchStart = (event: TouchEvent<HTMLElement>) => {
    initSectionTouch(event);
  };

  const handleSectionTouchMove = (event: TouchEvent<HTMLElement>) => {
    const newTouch = event.touches[0];

    if (BrowserService.isBrowser) {
      const touchLengthX = newTouch.clientX - touch.x;
      const touchLengthY = newTouch.clientY - touch.y;

      if (Math.abs(touchLengthY) > Math.abs(touchLengthX)) {
        initSectionTouch(event);

        return;
      }

      if (
        Math.abs(touchLengthX) > document.body.clientWidth * SWIPE_THRESHOLD &&
        slider.current
      ) {
        if (touchLengthX < 0) {
          slider.current.slickNext();
        } else {
          slider.current.slickPrev();
        }

        initSectionTouch(event);
      }
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const renderNavigationControl = (item: InterviewSlideDTO, index: number) => {
    const classes = classNames(styles.dot, {
      [styles.slickActive]: index <= currentSlideIndex,
      [styles.slickSelected]: index === currentSlideIndex,
    });

    return (
      <button
        className={classes}
        key={index}
        onClick={() => handleDotClick(index)}
      >
        <span className={styles.dotNumber}>0{index + 1}</span>
        <p className={styles.dotTitle}>{item.name}</p>
      </button>
    );
  };

  const items = data.items;
  return (
    <section
      ref={container}
      className={styles.root}
      onTouchStart={handleSectionTouchStart}
      onTouchMove={handleSectionTouchMove}
    >
      <div className={styles.navigation}>
        <ul className={styles.dots}>{items.map(renderNavigationControl)}</ul>

        <div className={styles.arrows}>
          <button onClick={handlePrev} className={styles.arrow}>
            <ArrowIcon className={styles.prev} />
          </button>
          <button onClick={handleNext} className={styles.arrow}>
            <ArrowIcon className={styles.next} />
          </button>
        </div>
      </div>
      <Slider
        ref={slider}
        beforeChange={beforeChange}
        afterChange={afterChange}
        arrows={false}
        touchMove={false}
      >
        {items.map(renderInterviewElement)}
      </Slider>
    </section>
  );
};

export default Interview;
