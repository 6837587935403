import { BenefitsSectionDTO } from '~/pages/careers/types';

import laptopIcon from './assets/new/Laptop.svg';
import formOfEmploymentIcon from './assets/new/Form.svg';
import remoteWorkIcon from './assets/new/Camp.svg';
import warsawsPalaceOfCultureIcon from './assets/new/Warsaw.svg';
import sickLeaveIcon from './assets/new/MedicalCare.svg';
import privateMedicalCareIcon from './assets/new/Icon.svg';
import paidHolidaysIcon from './assets/new/PaidHolidays.svg';
import lifeInsuranceIcon from './assets/new/LifeInsurance.svg';
import envelopeWithCashInsideIcon from './assets/new/Reimbursement.svg';
import languageIcon from './assets/new/Languages.svg';
import personalDevelopmentIcon from './assets/new/PersonalDevelopment.svg';
import psychotherapyIcon from './assets/new/Support.svg';
import conciergeServiceIcon from './assets/new/Consierge.svg';
import physiotherapyIcon from './assets/new/Physioterapy.svg';
import cafeteriaIcon from './assets/new/Cafeteria.svg';
import nannyServiceIcon from './assets/new/Nanny.svg';
import gymIllustration from './assets/new/Multisport.svg';
import iceCreamIcon from './assets/new/Snacks.svg';
import highFiveIllustration from './assets/new/TeamEvents.svg';
import chillRoomIcon from './assets/new/ChillRoom.svg';

const benefitsSection: BenefitsSectionDTO = {
  title: 'Our benefits',
  items: [
    {
      title: 'Gear with Apple logo and nice Dell monitor',
      image: {
        src: laptopIcon,
        alt: 'Laptop illustration',
      },
    },
    {
      title: 'Form of employment of your choosing',
      image: {
        src: formOfEmploymentIcon,
        alt: 'Form of employment illustration',
      },
    },
    {
      title: 'Remote work & flexible working hours',
      image: {
        src: remoteWorkIcon,
        alt: 'Remote work illustration',
      },
    },
    {
      title: 'Office in the centre of Warsaw',
      image: {
        src: warsawsPalaceOfCultureIcon,
        alt: "Warsaw's palace of culture illustration",
      },
    },
    {
      title: 'Fully paid sick leave',
      image: {
        src: sickLeaveIcon,
        alt: 'Sick leave illustration',
      },
    },
    {
      title: 'Private medical & dental care for you and your family',
      image: {
        src: privateMedicalCareIcon,
        alt: 'Private medical care illustration',
      },
    },
    {
      title: 'Paid holidays',
      image: {
        src: paidHolidaysIcon,
        alt: 'Paid holidays illustration',
      },
    },
    {
      title: 'Group life insurance',
      image: {
        src: lifeInsuranceIcon,
        alt: 'Life insurance illustration',
      },
    },
    {
      title: 'Reimbursement for courses & conferences',
      image: {
        src: envelopeWithCashInsideIcon,
        alt: 'Envelope with cash inside illustration',
      },
    },
    {
      title: 'Free access to language courses',
      image: {
        src: languageIcon,
        alt: 'Chinese letters illustration',
      },
    },
    {
      title: 'Personal development & voluntary days off',
      image: {
        src: personalDevelopmentIcon,
        alt: 'Person illustration',
      },
    },
    {
      title: 'Psychotherapists support',
      image: {
        src: psychotherapyIcon,
        alt: 'Calm person illustration',
      },
    },
    {
      title: 'Concierge Service',
      image: {
        src: conciergeServiceIcon,
        alt: 'Concierge Service illustration',
      },
    },
    {
      title: 'Home physiotherapy',
      image: {
        src: physiotherapyIcon,
        alt: 'Physiotherapy illustration',
      },
    },
    {
      title: 'Cafeteria platform',
      image: {
        src: cafeteriaIcon,
        alt: 'Coffee illustration',
      },
    },
    {
      title: 'Nanny services for parents',
      image: {
        src: nannyServiceIcon,
        alt: 'Happy kid illustration',
      },
    },
    {
      title: 'MultiSport Card',
      image: {
        src: gymIllustration,
        alt: 'Gym illustration',
      },
    },
    {
      title: 'Free snacks & ice-cream in the office',
      image: {
        src: iceCreamIcon,
        alt: 'Snacks illustration',
      },
    },
    {
      title: 'Team Building Events',
      image: {
        src: highFiveIllustration,
        alt: 'High-five illustration',
      },
    },
    {
      title: 'Chill-room with table football, PlayStation 4 & Wii U',
      image: {
        src: chillRoomIcon,
        alt: 'Chill-room illustration',
      },
    },
  ],
};

export default benefitsSection;
