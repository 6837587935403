import { JobsSectionDTO } from '~/pages/careers/types';

const jobsSection: JobsSectionDTO = {
  items: [
    // Most of Swing's job offers are fetched automatically from Lever.
    // If you want to add a custom one, add it here.
  ],
  cta: {
    title: 'Don’t see a position that fits you?',
    description:
      'Want to work at SwingDev? Looking for challenging projects? Send us your CV for future recruitments!',
    link: 'mailto:talent@swing.dev',
  },
};

export default jobsSection;
