import classNames from 'classnames';
import React from 'react';

import Button from '~/components/Button';

import { getIconUrl } from './utils';

import styles from './Jobs.module.scss';

type Props = {
  title: string;
  description: string;
  outline?: boolean;
  showIcon?: boolean;
  url: string;
  externalLink?: boolean;
  buttonLabel: string;
  onClick?: () => void;
  soon?: boolean;
};

export const Card: React.FC<Props> = (props) => {
  const classes = classNames(styles.card, {
    [styles.outline]: props.outline,
  });

  return (
    <article className={classes}>
      {props.showIcon && (
        <img
          className={styles.icon}
          src={getIconUrl(props.title)}
          alt=''
          role='presentation'
        />
      )}

      {props.soon && <p className={styles.redText}>Soon</p>}
      <h3 className={styles.title}>{props.title}</h3>
      <p className={styles.text}>{props.description}</p>

      <Button
        as={{
          tag: 'a',
          href: props.url,
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        className={styles.button}
        onClick={props.onClick}
        isSecondary
      >
        {props.buttonLabel}
      </Button>
    </article>
  );
};
