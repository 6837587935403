import React from 'react';

import { YOUTUBE_VIDEO_ALLOW_OPTIONS } from '~/constants';

import { KnowledgeSectionDTO } from '../../types';
import styles from './KnowledgeSharing.module.scss';

type Props = {
  data: KnowledgeSectionDTO;
};
const KnowledgeSharing: React.FC<Props> = ({ data }) => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.videoContainer}>
          <iframe
            title={data.event.title}
            className={styles.video}
            src={data.event.videoUrl}
            frameBorder='0'
            allow={YOUTUBE_VIDEO_ALLOW_OPTIONS.join(',')}
            allowFullScreen
          />
        </div>
      </div>
    </section>
  );
};

export default KnowledgeSharing;
