import { KnowledgeSectionDTO } from '~/pages/careers/types';

import learning from './assets/learning.jpg';

const skillsSection: KnowledgeSectionDTO = {
  learning: {
    title: 'Dedicated to learning and teaching',
    description:
      'At SwingDev we are dedicated to learning and sharing knowledge. We are one of very few software houses that have a Developer Evangelist on staff. We know how hard it is to efficiently learn software development, so we do everything we can to expand your skills and knowledge.',
    image: {
      src: learning,
      alt: 'microConf speaker',
    },
  },
  blog: {
    title: 'Medium',
    url: 'https://medium.com/swinginc',
    description:
      'We often share our knowledge on our Medium blog. Reviewing this material can be helpful before your recruitment interview.',
    buttonLabel: 'Read our articles',
  },
  event: {
    title: 'We’re part of Hippo',
    description:
      'SwingDev is a part of Hippo Insurance, the InsurTech company from Silicon Valley.',
    videoUrl: 'https://www.youtube-nocookie.com/embed/vq5wISDBOEI',
    buttonLabel: 'Join SwingDev',
  },
};

export default skillsSection;
