import { EntrySectionDTO } from '~/pages/careers/types';

import backgroundPhoto from './assets/careers-hero-2.jpg';

const entrySection: EntrySectionDTO = {
  title: 'Swing high. We are here to give you the push!',
  subtitle:
    'If you’re looking for a place that values your skills, enthusiasm, and desire to do the software the best possible way, come join us!”',
  buttonLabel: 'Check out our open positions',
  image: {
    src: backgroundPhoto,
    alt: 'Team photo',
  },
};

export default entrySection;
